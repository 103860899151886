export default function TicketSelector({prices, ticketCount, setTicketCount, setTicketType, ticketType}) {

    const totalPrice = (ticketType.basic * prices.base) +
        (ticketType.discounted * prices.discounted) +
        (ticketType.child * prices.child);

    // Handle quantity changes
    const updateTicketCount = (type, increment) => {
        // Calculate the new count
        const newCount = ticketType[type] + increment;

        // Check if within bounds (0 to 10 total tickets, and not negative)
        if (newCount < 0 || ticketCount + increment > 10) {
            return;
        }

        setTicketType({
            ...ticketType,
            [type]: newCount
        });

        setTicketCount(ticketCount + increment);
    };

    return (
        <>
            <div className="ticket-selector flex-column gap-m box-shadow-s">
                <h2>Vyberte počet jízdenek</h2>
                <p className="ticket-selector-max">Maximum 10 jízdenek celkem</p>

                <div className="ticket-type flex-row gap-s">
                    <div className="ticket-info flex-column">
                        <h3>Základní</h3>
                        <p className="ticket-selector-max">Pro všechny</p>
                        <p>{prices.base} Kč</p>
                    </div>
                    <div className="ticket-controls flex-row center-vertical">
                        <button className="ticket-btn" onClick={() => updateTicketCount('basic', -1)}>-</button>
                        <span className="ticket-count">{ticketType.basic}</span>
                        <button className="ticket-btn" onClick={() => updateTicketCount('basic', 1)}>+</button>
                    </div>
                </div>

                <div className="ticket-type flex-row gap-s">
                    <div className="ticket-info flex-column">
                        <h3>Zvýhodněná</h3>
                        <p className="ticket-selector-max">Pro studenty a lidi starší 65ti let</p>
                        <p>{prices.discounted} Kč</p>
                    </div>
                    <div className="ticket-controls flex-row center-vertical">
                        <button className="ticket-btn" onClick={() => updateTicketCount('discounted', -1)}>-</button>
                        <span className="ticket-count">{ticketType.discounted}</span>
                        <button className="ticket-btn" onClick={() => updateTicketCount('discounted', 1)}>+</button>
                    </div>
                </div>

                <div className="ticket-type flex-row gap-s no-border">
                    <div className="ticket-info flex-column">
                        <h3>Dětská</h3>
                        <p className="ticket-selector-max">Pro děti do 12ti let</p>
                        <p>{prices.child} Kč</p>
                    </div>
                    <div className="ticket-controls flex-row center-vertical">
                        <button className="ticket-btn" onClick={() => updateTicketCount('child', -1)}>-</button>
                        <span className="ticket-count">{ticketType.child}</span>
                        <button className="ticket-btn" onClick={() => updateTicketCount('child', 1)}>+</button>
                    </div>
                </div>

                <div className="ticket-summary flex-column">
                    <div className="flex-row justify-c-space-between">
                        <p>Celkem jízdenek:</p>
                        <p>{ticketCount}</p>
                    </div>
                    <div className="flex-row justify-c-space-between">
                        <p className="text-bold">Celková cena:</p>
                        <p className="text-bold">{totalPrice} Kč</p>
                    </div>
                </div>
            </div>
        </>
    );
}