import {Helmet} from "react-helmet";
import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {supabase} from "../komponenty/utils/supabase";
import {motion} from "framer-motion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import useScreenSize from "../komponenty/utils/useScreenSize";

function AktualitaClanek() {

    const params = useParams();
    const screen = useScreenSize();
    const canRun = useRef(false);

    let [info, setInfo] = useState([]);
    let [nadpis, setNadpis] = useState("AktualitaClanek");
    let [nadpisSize, setNadpisSize] = useState(0);
    let [nadpisTloustka, setNadpisTloustka] = useState(600);
    let [bonusLinks, setBonusLinks] = useState('')
    let [bonus, setBonus] = useState([]);

    useEffect(() => {
        void findId();
    }, [])

    useEffect(() => {
        if (canRun.current)
            fontSize(info.nadpis)
    })

    const findId = async () => {

        let id = params.aktualita_id.split('.')

        const { data } = await supabase
            .from('aktuality')
            .select()
            .eq('id', id[id.length - 1]);

        if (data.length !== 0) {
            setInfo(data[0]);
            setNadpis(data[0].nadpis)
            void bonusImages(data[0].bonus_img)
            fontSize(data[0].nadpis);
            canRun.current = true;
        } else {
            //TODO: Render a 404 page
        }
    }

    const bonusImages = async (json) => {
        if (json) {
            for (let i = 0; i < json.length; i++) {
                const { data } = await supabase
                    .from('aktuality_bonus_img')
                    .select()
                    .eq('id', json[i]);

                console.log(data)

                if (data.length !== 0) {
                    setBonus((prev) => [...prev, data[0]]);
                }
            }
        } else setBonus(["NULL"]);
    }

    const fontSize = (nadpis) => {
        let fontSize = screen.width/nadpis.toString().length;
        if (fontSize > 75)
            fontSize = 75;
        if (fontSize < 25)
            fontSize = 25;
        if (fontSize < 40 && nadpisTloustka <= 600 ) {
            setNadpisTloustka(600)
        } else if (nadpisTloustka !== 600)
            setNadpisTloustka(600)

        setNadpisSize(fontSize)
    }

    return (
        <>
            <Helmet>
                <title>{nadpis} | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <Link to={'/aktuality'} className={'blog-read-back'}>
                    <p  className={'text-white text-uppercase'}>
                        <FontAwesomeIcon icon={faAngleLeft} /> Zpět
                    </p>
                </Link>

                <div className={'blog-read-header flex-column center-vertical'}>
                    <img src={info.img}
                         className={'blog-read-image'}
                         style={{objectPosition: `0 ${info.pos}%`}}
                         alt={'Obrázek aktuality'}
                    />
                    <h1 className={'text-header text-center'} style={{fontSize: nadpisSize+'px', fontWeight: nadpisTloustka}}>{info.nadpis}</h1>
                </div>

                <div className={'blog-read-content flex-column center-vertical'}>
                    <div className={'flex-row blog-read-top'}>
                        <p  className={'text-muted text-left blog-read-subtitle'}>{info.popis}</p>
                        <p  className={'text-right'}>Napsáno dne <b>{info.datum}</b></p>
                    </div>
                    <div className={'flex-column center-vertical'}>
                        <p className={"text-justify"}>{info.obsah}</p>
                        {bonus[0] !== "NULL" ? <h1 className={'margin-m'}><b>Fotky</b></h1> : null}
                        {bonus[0] !== "NULL" ? bonus.map((obrazek) => (
                            <>
                                <img src={obrazek.url} className={"box-shadow-l"} alt={"Dodatečný obrázek"}/>
                                {obrazek.popisek ? <p style={{maxWidth: "40rem"}} className={"text-muted text-center"}>{obrazek.popisek}</p> : null}
                            </>
                        )) : null}
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default AktualitaClanek;