export default function ReservationSummary({ ticketType, prices, selectedSeats }) {

    const totalTickets = ticketType.basic + ticketType.discounted + ticketType.child;
    const totalPrice = (ticketType.basic * prices.base) +
        (ticketType.discounted * prices.discounted) +
        (ticketType.child * prices.child);

return (
            <>
                <div className="reservation-summary flex-column gap-m box-shadow-s">
                    <h2>Shrnutí rezervace</h2>

                    <div className="summary-section flex-column">
                        <h3>Jízdenky</h3>
                        {ticketType.basic > 0 && (
                            <div className="summary-item flex-row gap-s">
                                <div className="summary-info flex-column">
                                    <p>Základní ({prices.base} Kč)</p>
                                </div>
                                <div className="text-bold flex-row center-vertical">
                                    <span className="ticket-count">{ticketType.basic} ks</span>
                                </div>
                            </div>
                        )}
                        {ticketType.discounted > 0 && (
                            <div className="summary-item flex-row gap-s">
                                <div className="summary-info flex-column">
                                    <p>Zvýhodněná ({prices.discounted} Kč)</p>
                                </div>
                                <div className="text-bold flex-row center-vertical">
                                    <span className="ticket-count">{ticketType.discounted} ks</span>
                                </div>
                            </div>
                        )}
                        {ticketType.child > 0 && (
                            <div className="summary-item flex-row gap-s">
                                <div className="summary-info flex-column">
                                    <p>Dětská ({prices.child} Kč)</p>
                                </div>
                                <div className="text-bold flex-row center-vertical">
                                    <span className="ticket-count">{ticketType.child} ks</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="summary-section flex-column">
                        <h3>Vybraná místa</h3>
                        <div className="summary-seats">
                            {selectedSeats.map((carriage, idx) => (
                                carriage.length > 0 && (
                                    <div key={idx} className="summary-item flex-row gap-s">
                                        <div className="summary-info flex-column">
                                            <p>Vůz {idx + 1}</p>
                                        </div>
                                        <div className="text-bold flex-row center-vertical">
                                            <span className="ticket-count">{carriage.join(', ')}</span>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>

                    <div className="summary-total flex-column">
                        <div className="flex-row justify-c-space-between">
                            <p>Celkem jízdenek:</p>
                            <p>{totalTickets}</p>
                        </div>
                        <div className="flex-row justify-c-space-between">
                            <p className="text-bold">Celková cena:</p>
                            <p className="text-bold">{totalPrice} Kč</p>
                        </div>
                    </div>
                </div>
            </>
        );
}