import {useMemo, useState} from "react";

export default function RezVagBtx761Bar({seatMap, maxSeats, selectedSeatsNumber, setSelectedSeatsNumber, currentlySelectedSeats, setCurrentlySelectedSeats, carriagePos}) {

        const initialSeats = [
            { id: "1A", isOccupied: false, x: 8.8, y: 8 },
            { id: "2A", isOccupied: true,  x: 11.7, y: 8 },
            { id: "3A", isOccupied: false, x: 16.9, y: 8 },
            { id: "4A", isOccupied: false, x: 20, y: 8 },
            { id: "5A", isOccupied: false, x: 25.3, y: 8 },
            { id: "6A", isOccupied: false, x: 34, y: 8 },
            { id: "7A", isOccupied: true,  x: 39.2, y: 8 },
            { id: "8A", isOccupied: false, x: 42.3, y: 8 },
            { id: "9A", isOccupied: false, x: 47.4, y: 8 },
            { id: "10A", isOccupied: false, x: 50.5, y: 8 },
            { id: "11A", isOccupied: false, x: 55.7, y: 8 },
            { id: "12A", isOccupied: false, x: 58.7, y: 8 },
            { id: "13A", isOccupied: false, x: 64, y: 8 },

            { id: "1B", isOccupied: false, x: 8.8, y: 23.6 },
            { id: "2B", isOccupied: false, x: 11.7, y: 23.6 },
            { id: "3B", isOccupied: false, x: 16.9, y: 23.6 },
            { id: "4B", isOccupied: false, x: 20, y: 23.6 },
            { id: "5B", isOccupied: false, x: 25.3, y: 23.6 },
            { id: "6B", isOccupied: false, x: 34, y: 23.6 },
            { id: "7B", isOccupied: false, x: 39.2, y: 23.6 },
            { id: "8B", isOccupied: false, x: 42.3, y: 23.6 },
            { id: "9B", isOccupied: false, x: 47.4, y: 23.6 },
            { id: "10B", isOccupied: false, x: 50.5, y: 23.6 },
            { id: "11B", isOccupied: false, x: 55.7, y: 23.6 },
            { id: "12B", isOccupied: false, x: 58.7, y: 23.6 },
            { id: "13B", isOccupied: false, x: 64, y: 23.6 },

            { id: "1C", isOccupied: false, x: 8.8, y: 59.3 },
            { id: "2C", isOccupied: false, x: 11.7, y: 59.3 },
            { id: "3C", isOccupied: false, x: 16.9, y: 59.3 },
            // { id: "4C", isOccupied: false, x: 20, y: 59.3 },
            // { id: "5C", isOccupied: false, x: 25.3, y: 59.3 },
            { id: "6C", isOccupied: false, x: 34, y: 59.3 },
            { id: "7C", isOccupied: false, x: 39.2, y: 59.3 },
            { id: "8C", isOccupied: false, x: 42.3, y: 59.3 },
            { id: "9C", isOccupied: false, x: 47.4, y: 59.3 },
            { id: "10C", isOccupied: false, x: 50.5, y: 59.3 },
            { id: "11C", isOccupied: false, x: 55.7, y: 59.3 },
            { id: "12C", isOccupied: false, x: 58.7, y: 59.3 },
            { id: "13C", isOccupied: false, x: 64, y: 59.3 },

            { id: "1D", isOccupied: false, x: 8.8, y: 74.8 },
            { id: "2D", isOccupied: false, x: 11.7, y: 74.8 },
            { id: "3D", isOccupied: false, x: 16.9, y: 74.8 },
            // { id: "4D", isOccupied: false, x: 20, y: 74.8 },
            // { id: "5D", isOccupied: false, x: 25.3, y: 74.8 },
            { id: "6D", isOccupied: false, x: 34, y: 74.8 },
            { id: "7D", isOccupied: false, x: 39.2, y: 74.8 },
            { id: "8D", isOccupied: false, x: 42.3, y: 74.8 },
            { id: "9D", isOccupied: false, x: 47.4, y: 74.8 },
            { id: "10D", isOccupied: false, x: 50.5, y: 74.8 },
            { id: "11D", isOccupied: false, x: 55.7, y: 74.8 },
            { id: "12D", isOccupied: false, x: 58.7, y: 74.8 },
            { id: "13D", isOccupied: false, x: 64, y: 74.8 },
        ];

    const handleSeatClick = (seatId) => {
        // Find seat details
        const seat = seatMap.find((s) => s.id === seatId);
        console.log(seatMap);
        if (seat.occupied) {
            return;
        }

        // Check if the seat is already selected in the current carriage
        const alreadySelected = currentlySelectedSeats[carriagePos]?.includes(seatId);

        // Update the currentlySelectedSeats state while preserving the 2D structure
        setCurrentlySelectedSeats((prev) => {
            // Create a shallow copy of the outer array
            const updatedSeats = [...prev];


            // Update the sub-array for the specific carriage
            if (alreadySelected) {
                updatedSeats[carriagePos] = updatedSeats[carriagePos].filter((s) => s !== seatId);
                setSelectedSeatsNumber((prev) => prev - 1);
            } else if (selectedSeatsNumber < maxSeats) {
                updatedSeats[carriagePos] = [...updatedSeats[carriagePos], seatId];
                setSelectedSeatsNumber((prev) => prev + 1);
            }

            return updatedSeats;
        });
    };

    function combineSeatMapAndOccupancy(seatMap, occupancy) {
        // We assume here that the occupancy array is in the same order as the seatMap.
        let x =  occupancy.map((seatOcc, i) => {
            // Get the coordinate data for the seat, if it exists.
            const coord = seatMap[i];
            // Return a new object that contains:
            // - the id and occupied property from the occupancy data,
            // - the x and y coordinates (if available) from the seatMap,
            // - and the original seat label (id from seatMap) under "seatLabel".
            return { id: seatOcc.id, occupied: seatOcc.occupied, x: coord.x, y: coord.y };
        });

        console.log(x);
        return x;
    }

    const seatingMap = useMemo(() => {
        return combineSeatMapAndOccupancy(initialSeats, seatMap);
    }, [seatMap]);

    return (
        <div style={{position: "relative", width: "80%"}}>
            <div style={{width: "100%", aspectRatio: 937/161, position: "relative"}}>
                {seatingMap.map((seat) => {
                    const isSelected = currentlySelectedSeats[carriagePos].includes(seat.id);
                    const occupied = seat.occupied;

                    // Simple inline styles for seat states:
                    const seatStyle = {
                        position: "absolute",
                        width: "2%",
                        aspectRatio: "6/8",
                        cursor: occupied ? "not-allowed" : "pointer",
                        border: isSelected ? "2px solid green" : "1px solid #ccc",
                        backgroundColor: occupied ? "#fa9a9a" : isSelected ? "#b2f7b2" : "#fff",
                        left: seat.x + "%",
                        top: seat.y + "%",
                    };

                    return (
                        <div
                            key={seat.id}
                            style={seatStyle}
                            onClick={() => handleSeatClick(seat.id)}
                        >
                        </div>
                    );
                })}
            </div>
            <img style={{position: "absolute", width: "100%", top: 0, left: 0, zIndex: -1}}
                 src={'https://uulahnyfghryhcrmidxt.supabase.co/storage/v1/object/public/obrazky/9vagony_bar-balm.webp'}
                 alt={'Btx 761 s barem'}/>
        </div>
    )
}