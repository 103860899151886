import React from 'react';
import RezVagBtx761 from "./vagony/btx761";
import RezVagBtx761Bar from "./vagony/btx761bar";

const VagonMatcher = ({ id, seatMap, maxSeats, setSelectedSeatsMap, selectedSeatsNumber, setSelectedSeatsNumber, selectedSeatsMap, carriagePos }) => {

    switch (id) {
        case '8':
            return <RezVagBtx761 seatMap={seatMap} selectedSeatsNumber={selectedSeatsNumber} setSelectedSeatsNumber={setSelectedSeatsNumber} currentlySelectedSeats={selectedSeatsMap} setCurrentlySelectedSeats={setSelectedSeatsMap} maxSeats={maxSeats} carriagePos={carriagePos} />;
        case '9':
            return <RezVagBtx761Bar seatMap={seatMap} selectedSeatsNumber={selectedSeatsNumber} setSelectedSeatsNumber={setSelectedSeatsNumber} currentlySelectedSeats={selectedSeatsMap} setCurrentlySelectedSeats={setSelectedSeatsMap} maxSeats={maxSeats} carriagePos={carriagePos} />;
        default:
            return null;
    }
};

export default VagonMatcher;
